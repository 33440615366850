import React from 'react';
import { StyleSheet, View, ImageBackground, ScrollView } from 'react-native';
import { Text, Headline, Title, Paragraph, Divider, Caption } from 'react-native-paper';
import AspectView from './app/components/AspectView';

export default function App() {
  return (
    <ScrollView style={styles.container}>
      <AspectView style={[styles.section, styles.heroContainer]}>
        <ImageBackground style={styles.heroImageContainer} source={require('./assets/images/hero.jpg')}>
          <Headline>Wiheim</Headline>
        </ImageBackground>
      </AspectView>
      <View style={[styles.section, styles.textContainer]}>
        <Title style={styles.sectionTitle}>{'We are'}</Title>
        <Paragraph style={styles.sectionDescription}>{'Wiheim, smart home products maker.'}</Paragraph>
      </View>
      <View style={[styles.section, styles.textContainer]}>
        <Title style={styles.sectionTitle}>{'Our mission'}</Title>
        <Paragraph style={styles.sectionDescription}>{`is to improve people's lives through merging technology into users' homes.`}</Paragraph>
      </View>
      <Divider />
      <View style={[styles.section, styles.footer]}>
        <Caption>{'© Wiheim Limited 2021'}</Caption>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
  },
  section: {
    width: '100%',
  },
  heroContainer: {
    aspectRatio: 1/1.5,
  },
  heroImageContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    margin: 10,
    padding: 30,
  },
  sectionTitle: {
    fontSize: 40,
  },
  sectionDescription: {
    padding: 20,
    fontSize: 30,
    lineHeight: 32,
  },
  footer: {
    padding: 40,
  },
});
