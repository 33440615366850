// ref: https://baconbrix.gitbook.io/react-native-web/styling/aspect-ratio

import React from 'react';
import { View, StyleSheet } from 'react-native';

function AspectView(props) {
  const [layout, setLayout] = React.useState(null);

  const { aspectRatio = 1, ...inputStyle } =
    StyleSheet.flatten(props.style) || {};
  const style = [inputStyle, { aspectRatio }];

  if (layout) {
    const { width = 0, height = 0 } = layout;
    if (width === 0) {
      style.push({ width: height * aspectRatio, height });
    } else {
      style.push({ width, height: width * aspectRatio });
    }
  }

  return (
    <View
      {...props}
      style={style}
      onLayout={({ nativeEvent: { layout } }) => {
        console.log(layout);
        setLayout(layout);
      }}
    />
  );
};

export default AspectView;
